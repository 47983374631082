import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pfp from '../assets/scarlet.png';
import Background from '../assets/bg.jpg';
import MapPin from '../assets/map-pin.svg';
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import linkedIn from '../assets/linkedin-fill.svg';
import linkButtonArrow from '../assets/link-button-arrow.svg';
import instagram from '../assets/instagram.svg';
import { FiExternalLink } from "react-icons/fi";
import Depop from '../assets/depop.png';
import Poshmark from '../assets/poshmark.png';

const Profile = () => {

    const handleCopyLink = () => {
        navigator.clipboard.writeText('https://scarletrose.info')
            .then(() => {
                toast.success('Successfully copied URL!');
            })
            .catch(err => {
                toast.error('Failed to copy URL.');
            });
    };

    const handleInstagram = () => {
        window.open('https://instagram.com/f_ck.ish', '_blank');
    };

    const handleLinkedIn = () => {
        window.open('https://www.linkedin.com/in/scarlet-rose-a7ab7a325?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app', '_blank');
    };

    const handleDepop = () => {
        window.open('https://depop.app.link/IYrX2y0aDMb', '_blank');
    };

    const handlePoshmark = () => {
        window.open('https://posh.mk/XjxDkNiaDMb', '_blank');
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-base-300 relative overflow-hidden">
            {/* Slanted Blue Section */}
            <div className="absolute top-0 w-full h-[30vh] sm:h-[40vh] lg:h-[50vh] transform -skew-y-6 origin-top-left">
                <img src={Background} alt="Background" className="w-full h-full object-cover" />
            </div>

            {/* Copy Link Button in Top Right */}
            <div className="absolute top-5 right-5 cursor-pointer" onClick={handleCopyLink}>
                <FiExternalLink size={24} className='text-white text-bold' />
            </div>

            {/* Profile Card */}
            <div className="relative z-10 bg-gray-800 w-[90%] sm:w-[80%] lg:w-[60%] h-[65%] sm:h-[55%] lg:h-[50%] pb-5 rounded-lg flex flex-col items-center justify-start">
                {/* Profile Picture */}
                <div className="bg-gray-400 w-28 h-28 sm:w-36 sm:h-36 lg:w-44 lg:h-44 rounded-full absolute top-[-4rem] sm:top-[-5rem]">
                    <img src={Pfp} alt="Scarlet Rose" className="w-full h-full object-cover rounded-full" />
                </div>
                <div className="flex flex-col justify-center items-center mt-24 sm:mt-28 lg:mt-32 px-3 text-center">
                    <h1 className="text-lg sm:text-xl lg:text-2xl text-white font-bold mt-6">Scarlet Rose</h1>
                    <h3 className='text-sm sm:text-md lg:text-lg text-white font-md mt-2 text-center px-2'>Creative Visionary | Photographer | Vintage Fashion Curator | Capturing Timeless Style & Stories</h3>
                    <span className='flex flex-row justify-center items-center gap-2 py-2'>
                        <img src={MapPin} alt="Location" className="w-4 h-4" />
                        <p className="text-white text-xs sm:text-sm">Thomaston, CT</p>
                    </span>
                    <div className='flex flex-row justify-center items-center gap-4 mt-3'>
                        <a href="mailto:scarletj424@gmail.com" target="_blank" rel="noreferrer" className="btn text-xs sm:text-sm">
                            <MdEmail size={24} />
                            Email
                        </a>
                        <a href="tel:+12038150316" target="_blank" rel="noreferrer" className="btn text-xs sm:text-sm">
                            <FaPhoneAlt size={24} />
                            Phone
                        </a>
                    </div>
                </div>
            </div>

            {/* Social Links */}
            <div className="flex flex-col lg:flex-row gap-5 lg:gap-10 mt-8 sm:mt-10 justify-center items-center px-4">
                <button className="w-full sm:w-fit pr-5 h-20 bg-gray-800 relative group" onClick={handleLinkedIn}>
                    <img src={linkedIn} alt="LinkedIn" className="w-12 sm:w-14 lg:w-16 h-12 sm:h-14 lg:h-16 absolute top-2 -left-5 transition-transform duration-300 group-hover:translate-y-[-5px] animate-pulse-blue" />
                    <div className='flex flex-col justify-center items-left pl-16 pr-12 sm:pl-20 sm:pr-16 py-4'>
                        <h2 className='text-sm sm:text-base'>My LinkedIn Profile</h2>
                        <p className='text-xs sm:text-sm'>Check out my LinkedIn page</p>
                    </div>
                    <img src={linkButtonArrow} alt="Link Button Arrow" className="w-4 h-4 absolute bottom-7 right-4 transition-transform duration-300 group-hover:translate-x-2" />
                </button>
                <button className="w-full sm:w-fit pr-5 h-20 bg-gray-800 relative group" onClick={handleInstagram}>
                    <img src={instagram} alt="Instagram" className="w-12 sm:w-14 lg:w-16 h-12 sm:h-14 lg:h-16 absolute top-2 -left-5 transition-transform duration-300 group-hover:translate-y-[-5px] animate-pulse-pink" />
                    <div className='flex flex-col justify-center items-left pl-16 pr-12 sm:pl-20 sm:pr-16 py-4'>
                        <h2 className='text-sm sm:text-base'>My Instagram Profile</h2>
                        <p className='text-xs sm:text-sm'>Check out my photography page</p>
                    </div>
                    <img src={linkButtonArrow} alt="Link Button Arrow" className="w-4 h-4 absolute bottom-7 right-4 transition-transform duration-300 group-hover:translate-x-2" />
                </button>
                <button className="w-full sm:w-fit pr-5 h-20 bg-gray-800 relative group" onClick={handleDepop}>
                    <img src={Depop} alt="Depop" className="w-12 sm:w-14 lg:w-16 h-12 sm:h-14 lg:h-16 absolute top-2 -left-5 transition-transform duration-300 group-hover:translate-y-[-5px] animate-pulse-pink" />
                    <div className='flex flex-col justify-center items-left pl-16 pr-12 sm:pl-20 sm:pr-16 py-4'>
                        <h2 className='text-sm sm:text-base'>My Depop Catalog</h2>
                        <p className='text-xs sm:text-sm'>Curated Collection of Vintage Treasures</p>
                    </div>
                    <img src={linkButtonArrow} alt="Link Button Arrow" className="w-4 h-4 absolute bottom-7 right-4 transition-transform duration-300 group-hover:translate-x-2" />
                </button>
                <button className="w-full sm:w-fit pr-5 h-20 bg-gray-800 relative group" onClick={handlePoshmark}>
                    <img src={Poshmark} alt="Poshmark" className="w-12 sm:w-14 lg:w-16 h-12 sm:h-14 lg:h-16 absolute top-2 -left-5 transition-transform duration-300 group-hover:translate-y-[-5px] animate-pulse-pink" />
                    <div className='flex flex-col justify-center items-left pl-16 pr-12 sm:pl-20 sm:pr-16 py-4'>
                        <h2 className='text-sm sm:text-base'>My Poshmark Catalog</h2>
                        <p className='text-xs sm:text-sm'>Hand Selected Timeless Pieces</p>
                    </div>
                    <img src={linkButtonArrow} alt="Link Button Arrow" className="w-4 h-4 absolute bottom-7 right-4 transition-transform duration-300 group-hover:translate-x-2" />
                </button>
            </div>
            <div className="badge badge-primary mt-10 sm:mt-20 text-center">Full website coming soon</div>

            {/* Footer */}
            <footer className="footer footer-center bg-base-300 text-base-content p-4 absolute bottom-0 w-full">
                <aside>
                    <p className="text-xs sm:text-sm">Copyright © {new Date().getFullYear()} - All right reserved by Scarlet Rose</p>
                </aside>
            </footer>

            {/* Toast notifications */}
            <ToastContainer />
        </div>
    );
};

export default Profile;
