import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pfp from '../assets/scarlet.png';
import Background from '../assets/bg.jpg';
import MapPin from '../assets/map-pin.svg';
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import linkedIn from '../assets/linkedin-fill.svg';
import linkButtonArrow from '../assets/link-button-arrow.svg';
import instagram from '../assets/instagram.png';
import poshmark from '../assets/poshmark.png';
import depop from '../assets/depop.png';
import xLogo from '../assets/x-logo.png';
import tiktok from '../assets/tiktok.png';
import { FiExternalLink } from "react-icons/fi";

const Profile = () => {
    const handleCopyLink = () => {
        navigator.clipboard.writeText('https://scarletrose.info')
            .then(() => toast.success('Successfully copied URL!'))
            .catch(() => toast.error('Failed to copy URL.'));
    };

    const openLink = (url) => window.open(url, '_blank');

    return (
        <div className="min-h-screen w-screen flex flex-col items-center justify-center bg-base-300 relative">
            {/* Background Image */}
            <div className="absolute top-0 w-full h-1/2 transform -skew-y-6 origin-top-left">
                <img src={Background} alt="Background" className="w-full h-full object-cover" />
            </div>

            {/* Copy Link Button */}
            <div className="absolute top-5 right-5 cursor-pointer" onClick={handleCopyLink}>
                <FiExternalLink size={24} className="text-white text-bold" />
            </div>

            {/* Profile Card */}
            <div className="relative z-10 mt-28 sm:mt-20 bg-gray-800 w-full max-w-[90%] sm:max-w-[80%] md:max-w-[70%] lg:max-w-[60%] xl:max-w-[50%] 2xl:max-w-[40%] h-auto rounded-lg flex flex-col items-center justify-start p-4 sm:p-6">
                {/* Profile Picture */}
                <div className="bg-gray-400 w-28 h-28 sm:w-36 sm:h-36 md:w-48 md:h-48 rounded-full -mt-16 sm:-mt-20">
                    <img src={Pfp} alt="Scarlet Rose" className="w-full h-full object-cover rounded-full" />
                </div>
                <div className="flex flex-col justify-center items-center mt-6 sm:mt-10">
                    <h1 className="text-xl sm:text-2xl md:text-3xl text-white font-bold">Scarlet Rose</h1>
                    <h3 className="text-sm sm:text-md md:text-lg text-white font-md mt-2 text-center">Creative Visionary | Photographer | Vintage Fashion Curator</h3>
                    <div className="flex flex-row justify-center items-center gap-2 py-2">
                        <img src={MapPin} alt="Location" className="w-3 h-3 sm:w-4 sm:h-4" />
                        <p className="text-white text-xs sm:text-sm">Thomaston, CT</p>
                    </div>
                    <div className="flex flex-row justify-center items-center gap-4 mt-3">
                        <a href="mailto:contact@staplestreetstyles.com" target="_blank" rel="noreferrer" className="btn">
                            <MdEmail size={20} className="sm:size-8" /> Email
                        </a>
                        <a href="tel:+12038150316" target="_blank" rel="noreferrer" className="btn">
                            <FaPhoneAlt size={20} className="sm:size-7" /> Phone
                        </a>
                    </div>
                </div>
            </div>

            {/* Social Links */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-3 mt-10 w-full max-w-[90%] sm:max-w-[80%] md:max-w-[70%] lg:max-w-[60%] xl:max-w-[50%] 2xl:max-w-[40%]">
                <button className="w-full h-20 bg-gray-800 relative group" onClick={() => openLink('https://instagram.com/staplestreetstyles')}>
                    <img src={instagram} alt="Instagram" className="w-10 h-10 sm:w-12 sm:h-12 md:top-2 md:w-16 md:h-16 absolute top-5 left-3 sm:-left-5 rounded-xl transition-transform duration-300 group-hover:translate-y-[-5px] animate-pulse-pink" />
                    <div className="flex flex-col justify-center items-start pl-16">
                        <h2 className="text-sm sm:text-md md:text-lg">Instagram</h2>
                        <p className="text-xs sm:text-sm">Check out Staple Street Styles</p>
                    </div>
                    <img src={linkButtonArrow} alt="Link Button Arrow" className="hidden md:block w-3 h-3 sm:w-4 sm:h-4 absolute bottom-7 right-4 transition-transform duration-300 group-hover:translate-x-2" />

                </button>

                <button className="w-full h-20 bg-gray-800 relative group" onClick={() => openLink('https://www.linkedin.com/in/scarlet-rose-a7ab7a325')}>
                    <img src={linkedIn} alt="LinkedIn" className="w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:top-2 md:h-16 absolute top-5 left-3 sm:-left-5 rounded-xl transition-transform duration-300 group-hover:translate-y-[-5px] animate-pulse-blue" />
                    <div className="flex flex-col justify-center items-start pl-16">
                        <h2 className="text-sm sm:text-md md:text-lg">LinkedIn</h2>
                        <p className="text-xs sm:text-sm">See my professional profile</p>
                    </div>
                    <img src={linkButtonArrow} alt="Link Button Arrow" className="hidden md:block w-3 h-3 sm:w-4 sm:h-4 absolute bottom-7 right-4 transition-transform duration-300 group-hover:translate-x-2" />

                </button>

                <button className="w-full h-20 bg-gray-800 relative group" onClick={() => openLink('https://poshmark.com/closet/stapleststyles')}>
                    <img src={poshmark} alt="Poshmark" className="w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:top-2 md:h-16 absolute top-5 left-3 sm:-left-5 rounded-xl transition-transform duration-300 group-hover:translate-y-[-5px] animate-pulse-blue" />
                    <div className="flex flex-col justify-center items-start pl-16">
                        <h2 className="text-sm sm:text-md md:text-lg">Poshmark</h2>
                        <p className="text-xs sm:text-sm">Shop my curated collection</p>
                    </div>
                    <img src={linkButtonArrow} alt="Link Button Arrow" className="hidden md:block w-3 h-3 sm:w-4 sm:h-4 absolute bottom-7 right-4 transition-transform duration-300 group-hover:translate-x-2" />

                </button>

                <button className="w-full h-20 bg-gray-800 relative group" onClick={() => openLink('https://depop.com/staplestreetstyles')}>
                    <img src={depop} alt="Depop" className="w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:h-16 absolute md:top-2 top-5 left-3 sm:-left-5 rounded-xl transition-transform duration-300 group-hover:translate-y-[-5px] animate-pulse-blue" />
                    <div className="flex flex-col justify-center items-start pl-16">
                        <h2 className="text-sm sm:text-md md:text-lg">Depop</h2>
                        <p className="text-xs sm:text-sm">Find unique fashion items</p>
                    </div>
                    <img src={linkButtonArrow} alt="Link Button Arrow" className="hidden md:block w-3 h-3 sm:w-4 sm:h-4 absolute bottom-7 right-4 transition-transform duration-300 group-hover:translate-x-2" />

                </button>

                <button className="w-full h-20 bg-gray-800 relative group" onClick={() => openLink('https://x.com/stapleststyles')}>
                    <img src={xLogo} alt="X (Twitter)" className="w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:h-16 absolute md:top-2 top-5 left-3 sm:-left-5 rounded-xl transition-transform duration-300 group-hover:translate-y-[-5px] animate-pulse-blue" />
                    <div className="flex flex-col justify-center items-start pl-16">
                        <h2 className="text-sm sm:text-md md:text-lg">X</h2>
                        <p className="text-xs sm:text-sm">Follow my updates</p>
                    </div>
                    <img src={linkButtonArrow} alt="Link Button Arrow" className="hidden md:block w-3 h-3 sm:w-4 sm:h-4 absolute bottom-7 right-4 transition-transform duration-300 group-hover:translate-x-2" />

                </button>

                <button className="w-full h-20 bg-gray-800 relative group" onClick={() => openLink('https://www.tiktok.com/@staplestreetstyles')}>
                    <img src={tiktok} alt="TikTok" className="w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:h-16 absolute md:top-2 top-5 left-3 sm:-left-5 rounded-xl transition-transform duration-300 group-hover:translate-y-[-5px] animate-pulse-blue" />
                    <div className="flex flex-col justify-center items-start pl-16">
                        <h2 className="text-sm sm:text-md md:text-lg">TikTok</h2>
                        <p className="text-xs sm:text-sm">Watch my videos</p>
                    </div>
                    <img src={linkButtonArrow} alt="Link Button Arrow" className="hidden md:block w-3 h-3 sm:w-4 sm:h-4 absolute bottom-7 right-4 transition-transform duration-300 group-hover:translate-x-2" />

                </button>
            </div>

            <div className="badge badge-primary mt-5">Full website coming soon</div>

            {/* Footer */}
            <footer className="footer footer-center bg-base-300 text-base-content p-4 absolute bottom-0">
                <aside>
                    <p>Copyright © {new Date().getFullYear()} - All rights reserved by Scarlet Rose</p>
                </aside>
            </footer>

            {/* Toast notifications */}
            <ToastContainer />
        </div>
    );
};

export default Profile;